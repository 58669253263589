<template>
	<section>
		<div class="row">
			<div class="col-12 mb-1" :class="{ 'col-lg-4': !hideBtns }">
				<select class="form-control mx-auto" @change="onChange" v-if="inputValue.length > 0" ref="selector">
					<option v-for="(obj, index) in inputValue" :key="index" :value="index" :selected="index === 0">
						{{ obj.name }}
					</option>
				</select>
			</div>
			<div class="col-auto">
				<b-button @click="addGroup" class="btn-add" :disabled="disabled" v-show="$can('update', 'Company')"> Tilføj gruppe</b-button>
			</div>
			<div class="col-auto">
				<b-button @click="deleteGroup" class="btn-delete" :disabled="disabled" v-show="$can('update', 'Company')"> Fjern gruppe</b-button>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	name: 'CompanyGroupHandler',
	props: {
		value: Array,
		disabled: Boolean,
	},
	mounted() {},
	computed: {
		inputValue: {
			get() {
				return this.value
			},
			set(val) {
				this.$emit('input', val)
			},
		},
	},
	methods: {},
}
</script>
<style lang="scss"></style>
