<template>
	<section class="container">
		<div class="row justify-content-end">
			<div class="col-auto">
				<BaseActionButton type="button" v-on:on-click="setEditable" v-if="$can('update', 'Company', 'Insurance')">
					<font-awesome-icon :icon="['far', 'edit']" class="icon" />
				</BaseActionButton>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-auto p-0">
				<h3><b>Pensionsaftale</b> {{ companyPension.pension.name }}</h3>
			</div>
		</div>
		<b-form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-12">
					<hr />
				</div>
			</div>

			<div class="row">
				<div class="col-auto">
					<div class="row">
						<div class="col-12 order-md-first"><b>Grupper</b></div>
						<b-card>
							<div v-for="(groupId, index) in companyPension.groups" :key="index" :variant="'success'">
								<b> {{ index + 1 }} - {{ getGroupName(groupId) }}</b>
							</div>
						</b-card>
						<div class="col-auto order-md-2">
							<BaseActionButton class="btn-add" type="button" @click.native="addGroup" :disabled="disabled"> Tilføj gruppe </BaseActionButton>
						</div>
						<div class="col-auto order-md-3">
							<BaseActionButton class="btn-update" type="button" @click.native="removeGroup" :disabled="disabled"> Fjern gruppe </BaseActionButton>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-auto p-0">
					<h3>
						<b>Navn</b>
					</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-4">
					<b-form-input v-model="companyPension.pension.name" :disabled="disabled" />
				</div>
			</div>
			<div class="row" v-if="companyPension">
				<company-pension
					v-model="companyPension.pension"
					:suppliers="suppliers"
					:disabled="disabled"
					@groupSupplierTemplateChanged="loadSupplierTemplatePension"
				/>
			</div>

			<div class="row">
				<div class="col-3">
					<BaseActionButton
						class="w-100 btn-update"
						v-if="$can('update', 'Company', 'Pensions')"
						v-bind:clicked="clicked"
						v-bind:disabled="disabled || clicked"
						type="submit"
					>
						{{ $t('global.update') }}
					</BaseActionButton>
				</div>
			</div>

			<div class="col-auto ml-auto d-none d-md-block text-right">
				<BaseActionButton class="btn-delete" type="button" @click.native="deletePension" :disabled="disabled"> Slet pensionaftale </BaseActionButton>
			</div>
			<div class="row mt-5 mb-5">
				<div class="col-auto"></div>
			</div>
		</b-form>
	</section>
</template>
<script>
import CompanyPension from '@/modules/general/company/components/CompanyPension'
import CompanyGroupHandler from '@/modules/general/company/components/CompanyGroupHandler'
import { superModel } from '@/models/superModel'

export default {
	name: 'CompanyPension',
	components: {
		'company-pension': CompanyPension,
		'group-dropdown': CompanyGroupHandler,
	},

	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	data() {
		return {
			disabled: true,
			clicked: false,
		}
	},
	computed: {
		groups() {
			return this.$store.getters['companyVuex/groups']
		},
		id() {
			return this.$route.params.companyId
		},
		companyId() {
			return this.$route.params.companyId
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		pensionId() {
			return this.$route.params.pensionId
		},
		pension() {
			return this.companyPension?.pension
		},
		companyPension() {
			return this.$store.getters['companyVuex/pension'](this.pensionId)
		},
	},
	methods: {
		setEditable() {
			this.disabled = !this.disabled
		},
		async loadSupplierTemplatePension({ index, supplierId, templateId }) {
			var template = Object.assign({}, this.suppliers.find((supplier) => supplier.id === supplierId).groupTemplates[index])
			var templatePension = superModel('pension', template.pension)
			templatePension.supplierId = supplierId
			templatePension.supplierTemplateId = templateId
			templatePension.name = this.pension.name
			templatePension.clearComments()
			await this.$store.commit('companyVuex/setPension', {
				id: this.pensionId,
				pension: templatePension,
			})
		},
		async addGroup() {
			var inputOptions = this.groups
				.filter((group) => {
					return !this.companyPension.groups.includes(group.id)
				})
				.map((group) => {
					return group.name
				})
			const { value: groupIndex } = await this.$swal.fire({
				title: 'Vælg en gruppe',
				input: 'radio',
				inputOptions: inputOptions,
				inputPlaceholder: 'Vælg en gruppe',
				showCancelButton: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value !== '') {
							resolve()
						} else {
							resolve('Du skal vælge en gruppe')
						}
					})
				},
			})
			if (groupIndex) {
				var groupName = inputOptions[groupIndex]
				this.companyPension.groups.push(this.groups.find((group) => group.name == groupName).id)
			}
		},
		async deletePension() {
			await this.$swal
				.fire({
					title: 'Er du sikker på at du vil slette denne forsikringsaftale?',
					text: 'Denne handling kan ikke fortrydes, og alle medarbejdere der er tilknyttet til denne aftale via gruppen vil miste deres forsikringsaftale.',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Ja slet!',
					showLoaderOnConfirm: true,
					preConfirm: async () => {
						try {
							var companyId = this.companyId
							var pensionId = this.pensionId
							var result = await this.$store.dispatch('companyVuex/deletePension', { companyId, pensionId })
							return result
						} catch (error) {
							console.log(error)
							this.$swal.showValidationMessage(`Request failed: ${error.message}`)
						}
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.toast('Success', 'En pensionsaftale er blevet slettet', true)
						this.clicked = false
						this.$router.push({ name: 'CompanyPensionList', params: { companyId: this.companyId } })
					}
				})
		},
		async removeGroup() {
			var inputOptions = this.groups
				.filter((group) => {
					return this.companyPension.groups.includes(group.id)
				})
				.map((group) => {
					return group.name
				})
			const { value: groupIndex } = await this.$swal.fire({
				title: 'Vælg en gruppe',
				input: 'radio',
				inputOptions: inputOptions,
				inputPlaceholder: 'Vælg en gruppe',
				showCancelButton: true,
				inputValidator: (value) => {
					return new Promise((resolve) => {
						if (value !== '') {
							resolve()
						} else {
							resolve('Du skal vælge en gruppe')
						}
					})
				},
			})
			if (groupIndex) {
				var groupName = inputOptions[groupIndex]
				this.companyPension.groups.splice(this.companyPension.groups.indexOf(this.groups.find((group) => group.name == groupName).id), 1)
			}
		},
		getGroupName(groupId) {
			return this.company.groups.find((group) => group.id == groupId)?.name
		},
		async onSubmit() {
			this.clicked = true
			var pension = this.companyPension
			var companyId = this.id
			var pensionId = pension.id
			var data = pension
			try {
				var result = await this.$store.dispatch('companyVuex/updateCompanyPension', {
					data,
					companyId,
					pensionId,
				})
				this.clicked = false
				this.toast('Success', 'Pensionsaftalen er nu opdateret', true)
			} catch (error) {
				this.clicked = false
				this.toast(error.response.data.code, error.response.data.message, false)
			}
		},
	},
}
</script>
<style lang="scss">
@import '@/styles/pages/companyAdd.scss';
</style>
