<template>
	<section class="col-12">
		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.supplier') }}</b>
				</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 col-12">
				<supplier v-model="pension.supplierId" :suppliers="suppliers" :disabled="disabled" />
			</div>
		</div>

		<template v-if="pension.supplierId">
			<div class="row">
				<div class="col-auto p-0 mt-3">
					<h5>
						<b>{{ $t('global.title.groupTemplate') }}</b>
					</h5>
				</div>
			</div>

			<div class="row">
				<div class="col-lg-4 col-12">
					<supplier-policy
						v-model="pension.supplierTemplateId"
						:groupTemplates="getGroupTemplates"
						:disabled="disabled"
						@supplierPolicyTemplateChanged="groupTemplatePicked"
					/>
				</div>
			</div>
		</template>

		<template v-if="pension.supplierId && $can('read', 'Supplier', 'InsurancePriceGroups')">
			<div class="row">
				<div class="col-auto p-0 mt-3">
					<h5>
						<b>{{ $t('global.title.supplierPriceGroup') }}</b>
					</h5>
				</div>
			</div>

			<div class="row">
				<div class="col-4">
					<InputDropdown v-model="pension.supplierPriceGroup" :options="getSupplierPensionPriceGroups" :disabled="disabled" />
				</div>
			</div>
		</template>

		<div class="row">
			<div class="col-auto p-0 mt-3">
				<h5>
					<b>Pension startdato obligatorisk (checkbox)</b>
				</h5>
			</div>
		</div>

		<div class="row">
			<div class="col-4">
				<InputCheck v-if="pension.supplierId" v-model="pension.pensionIsMandatory" :disabled="disabled" />
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row">
			<pension v-if="pension.supplierId" v-model="pension" :disabled="disabled" :pensionIndex="pensionIndex" />
		</div>
	</section>
</template>

<script>
import Supplier from '@/modules/global/components/Supplier'
import SupplierPolicy from '@/modules/global/components/SupplierPolicyTemplate'
import Pension from '@/modules/global/components/Pension.vue'
import Swal from 'sweetalert2'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown'
import InputCheck from '@/modules/global/atomComponents/InputCheck'

export default {
	name: 'CompanyPension',
	components: {
		'supplier': Supplier,
		'supplier-policy': SupplierPolicy,
		'pension': Pension,
		InputDropdown,
		InputCheck,
	},
	props: {
		value: Object,
		pensionIndex: Number,
		suppliers: Array,
		disabled: Boolean,
	},
	data() {
		return {}
	},
	methods: {
		groupTemplatePicked(index) {
			Swal.fire({
				title: 'Overskrive pensionaftale',
				text: 'Vil du overskrive pensionaftalen? - Du skal dog stadig gemme ændringer.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja overskriv!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$emit('groupSupplierTemplateChanged', {
						index: index,
						supplierId: this.pension.supplierId,
						templateId: this.pension.supplierTemplateId,
					})
					Swal.fire('Overskrevet!', '..', 'success')
				}
			})
		},
	},
	computed: {
		pension: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		getGroupTemplates() {
			var result = this.suppliers.find((supplier) => supplier.id === this.pension.supplierId).groupTemplates
			return result
		},
		getSupplierPensionPriceGroups() {
			var result = this.suppliers.find((supplier) => supplier.id === this.pension.supplierId).pensionPriceGroups
			return result
		},
	},
}
</script>

<style></style>
